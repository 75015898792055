
import { IonPage, IonContent, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { defineComponent } from 'vue';
import * as rec from '@/common/records';

export default defineComponent({
  name: 'Synchronizer',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow,
  },
  data() {
    return {
      records: [] as any
    }
  },
  ionViewDidEnter() {
    const syncData = localStorage.getItem('cache:wma:records')
    if (syncData) {
      this.records = JSON.parse(syncData)
    }
  },
  ionViewDidLeave() {
    localStorage.setItem('cache:wma:records', JSON.stringify(this.records))
  },
  methods: {
    computeDescription(rec: any) {
      let description = rec.type
      if (rec.type == 'Waste Delivery - In') {
        description = ''.concat(description, ' by ', rec.payloads[0].out_username)
        if (rec.payloads[3]) {
          description = ''.concat(description, ' with instant money withdrawal.')
        }
      }
      if (rec.type == 'Money Outcome') {
        const qnt = rec.payloads[0].value * -1
        description = ''.concat(description, ' by ', rec.payloads[0].out_username, ' (quantity: ', qnt.toString(), ')')
      }
      if (rec.type == 'Register New User') {
        if (rec.payloads[0].user_data?.username) {
          description = ''.concat(description, ' - ', rec.payloads[0].user_data?.username)
        }
      }
      if (rec.type == 'Add Employee Role') {
        if (rec.payloads[0].username) {
          description = ''.concat(description, ' to ', rec.payloads[0].username)
        }
      }
      return description
    },
    async syncEntry(req: any) {
      const idx = this.records.indexOf(req)
      if (req.type == 'Waste Delivery - In' || req.type == 'Waste Delivery - Out') {
        const postEntry = await rec.saveWasteDelivery.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else if (req.type == 'Money Outcome') {
        const postEntry = await rec.saveMoneyOutcome.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else if (req.type == 'Fix Stock Inventory') {
        const postEntry = await rec.saveFixStock.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else if (req.type == 'Update Price List') {
        const postEntry = await rec.updatePricing.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else if (req.type == 'Register New User') {
        const postEntry = await rec.saveUserProfile.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else if (req.type == 'Add Employee Role') {
        const postEntry = await rec.addUserRole.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else if (req.type == 'Revoke Employee Role') {
        const postEntry = await rec.revokeUserRole.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else if (req.type == 'Update Employee Role') {
        const postEntry = await rec.updateUserRole.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      } else {
        const postEntry = await rec.updateTransaction.apply(this, req.payloads)
        this.parseQueryResult(postEntry, req)
      }
      this.updateRecord(idx, req)
    },
    parseQueryResult(resp: any, request: any) {
      if (resp?.status == 201) {
        request['response'] = 'Operation Confirmed.'
        request['status'] = "saved"
        return
      } else if (resp?.status == 400) {
        request['response'] = JSON.stringify(resp.data).replaceAll(/[{}_]/g, ' ')
      } else {
        request['response'] = JSON.stringify(resp.statusText)
      }
      request['status'] = "error"
      return
    },
    updateRecord(reqId: any, update: any) {
      this.records[reqId] = update
      localStorage.setItem('cache:wma:records', JSON.stringify(this.records))
    },
    async syncAll() {
      const undoneRequests = this.records.filter((el: any) => { return el.status != "saved"})
      for (const entry of undoneRequests) {
        await this.syncEntry(entry)
      }
    },
    async clearDone() {
      const undoneRequests = this.records.filter((el: any) => { return el.status != "saved"})
      this.records = undoneRequests
      localStorage.setItem('cache:wma:records', JSON.stringify(this.records))
    }
  }
});
